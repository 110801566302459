<template>
  <!-- <div class="contact__container"> -->
    <div class="contact__container">
      <h1>Contact</h1>
      <div class="contact__container--right">
        <h3>Email</h3>
        <span>contact@nohyia.com</span>
        <h3>Socials</h3>
        <div class="contact__container--right__icons">
          <img 
            src="@/assets/social-instagram.png"
            width="30"
            height="30"
            @click="goToInstagram"
            class="link"
            :alt="$t('instagramLink')"
          />
          <img
            src="@/assets/social-threads.png"
            width="30"
            height="30"
            @click="goToThread"
            class="link"
            :alt="$t('threadLink')"
          />
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script setup>
const goToInstagram = () => {
  window.location.href = "https://www.instagram.com/nohyiafr/";
}

const goToThread = () => {
  window.location.href = "https://www.threads.net/@nohyiafr";
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
}

.contact__container {
  display: flex;
  flex-direction: row;
  background-color: black;
  padding: 10px 41% 60px 120px;
  color: white;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
    padding: 10px 41% 60px 30px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
  }

  &--right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__icons {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
}
</style>
